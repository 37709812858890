@import '@/assets/styles/mixins';

.loading {
  display: inline-flex;
  justify-content: center;
  gap: rem(8px);
  width: 100%;
  color: currentColor;
  &__dot {
    color: currentColor;
    animation: dot-keyframes 1.5s infinite ease-in-out;
    background-color: currentColor;
    border-radius: rem(6px);
    display: inline-block;
    height: rem(6px);
    width: rem(6px);

    &:nth-child(2) {
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      animation-delay: 1s;
    }
  }
}
@keyframes dot-keyframes {
  0% {
    opacity: 0.4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.4;
    transform: scale(1, 1);
  }
}

