@import '@/assets/styles/mixins';

.user-management {
	.table-wrapper {
    display: flex;
    position: relative;
    overflow: auto;
    width: 100%;
    height: auto;
    // min-height: rem(250px);
    max-height: rem(570px);
    margin-top: rem(46px);
    container-type: inline-size;
    overflow-y: auto;
    flex: 1 1;
    background-color: transparent;

    input[type='checkbox'] {
      width: rem(20px);
      height: rem(20px);
      border-radius: rem(2px);
      margin: initial;
      cursor: pointer;
    }

		.is-nodata-available,
		.is-data-loading {
			padding: 30px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
		}

    .is-nodata-available {
      max-width: rem(364);
      color: rgba(6, 17, 10, 0.5);
      line-height: rem(30px);
      text-align: center;
      font-size: rem(25px);
      font-weight: 500;
    }
    .table-body--loading {
      filter: blur(10px);
    }

    table {
      width: 100%;
			height: fit-content;
      background-color: var(--white);

      th.select, td.select {
        text-align: center;
        width: rem(50px) !important;
        min-width: rem(50px) !important;
      }
      thead {
        tr {
          border-bottom: 1px solid #dfdfdf;
          th {
            &:not(th.select) {
              padding-top: 17px;
              padding-bottom: 10px;
              padding-left: 10px;
							padding-right: 10px;
						}
						&.select {
						}
            color: #343a40;
            font-family: var(--font-family-Roboto);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
          }
        }
      }

      tbody {
        tr {
          border-top: 1px solid #dfdfdf;
          &:hover {
            background-color: rgba(88, 5, 250, 0.07);
          }
          td {
            &:not(td.select) {
              padding-top: 13px;
              padding-bottom: 14px;
              padding-left: 10px;
							padding-right: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &.status {
              text-align: center;
              .info-status {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                height: rem(24px);
                border-radius: rem(26px);
                padding: 0 rem(10px);
                font-size: rem(14px);
                font-weight: 500;
                color: var(--white);
                margin: auto;
                &--ready {
                  background-color: #02cc54;
                }
                &--pending {
                  background-color: #ffbf1b;
                }
              }
            }

            color: #4f4f4f;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
						height: 50px;
          }
        }
      }
    }
  }
  .table-pagination {
    margin-top: auto;
  }
}