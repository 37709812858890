@import '@/assets/styles/mixins';

.overview {
  height: calc(100dvh - 80px);

  &__title {
    font-size: rem(28px);
    line-height: rem(34px);
    font-weight: 500;
  }

  &-token-transaction {
    display: flex;
    flex-wrap: wrap;
    margin-top: rem(26px);
    gap: rem(10px);
    container-type: inline-size;
    container-name: overview;
  }

  &-component-loading {
    svg {
      animation: rotate 2s linear infinite;
    }
  }

  &-info {
    border-radius: rem(10px);
    height: rem(78px);
    padding: rem(14px) rem(4px) rem(7px);
    background: #fff;
    &-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
    }
    &__item {
      position: relative;
      display: flex;
      flex-direction: column;
      padding-left: rem(28);
      padding-right: rem(28);
      gap: rem(8px);
      font-weight: 500;
      color: #8690a5;
      & + .overview-info__item {
        &:before {
          position: absolute;
          left: 0;
          content: '';
          top: 0;
          bottom: 0;
          width: 1px;
          background-color: #eaeaea;
        }
      }
    }
    &__title {
      font-size: 15px;
    }
    &__value {
      font-size: 28px;
      color: var(--black);
      &.is-loaded {
        color: #8690a5;
      }
      .loading {
        justify-content: flex-start;
      }
    }
  }

  * + &-info {
    margin-top: rem(40px);
  }
}

@keyframes rotate {
	0%{
		 transform: rotate(0deg);
		}
 100%{
		 transform: rotate(-360deg);
		}
 }

 
