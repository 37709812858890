@import '@/assets/styles/mixins';

.verification-page {
  display: flex;
  flex: 1;
  position: relative;
  display: flex;
  &--congratulations {
    background: url('/assets/images/preview.png') no-repeat center center;
  }
  img {
    display: inline-flex;
    width: fit-content;
  }
  .container {
    padding: rem(25px) 0;
  }
  &__layer,
  &__content {
    display: flex;
    flex: 1;
  }
  &__content {
    flex-direction: column;
    padding: rem(70px) rem(16px) rem(22px);
  }
  &__layer {
    gap: rem(50px);
    // ;
  }
  &__logo {
    display: inline-flex;
    color: var(--purple);
    height: rem(32px);
    svg {
      height: inherit;
    }
  }
  &__sponsor {
    margin: auto auto 0;
    width: rem(184px);
    height: rem(53px);
    color: inherit;
  }
  * + &__stepper {
    margin-top: rem(36px);
  }
}

.verification-page {
  .container {
    .verify-step {
      &--1 {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &--2 {
        width: 100%;
      }

      @media screen and (min-width: 992px) {
        &--3 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      }

      &__btn {
        margin-top: rem(42px);
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .verification-page {
    &__content {
      padding: rem(70px) 0 rem(22px) rem(40px);
    }
    &__logo {
      max-width: rem(381px);
      height: rem(40px);
    }

    .container {
      height: 100%;
      display: flex;
      .verify-step {
        &--1 {
          flex: 1;
          justify-content: center;
        }
      }
    }
    &__aside {
      display: flex;
      align-items: center;
      padding-right: rem(40px);
      max-height: 100dvh;
      .aside-wrapper {
        position: relative;
        max-width: rem(378px);
        height: calc(100vh - rem(140px));
        border-radius: rem(20px);
        background-color: var(--black);
        padding-top: rem(15px);
        padding-left: rem(32px);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: rem(20px);
        border-bottom-right-radius: rem(20px);
      }

      svg {
        position: absolute;
        margin: initial;
        width: rem(61px);
        height: rem(44px);
        color: var(--white);
        right: rem(30);
        bottom: rem(25);
        z-index: 22;
      }
    }
  }
}

// second screen element
// TODO! must be replace to another part
.verify-pwd {
  &__title {
    font-size: rem(30px);
    font-weight: 700;
  }
  &__desc {
    font-size: 16px;
  }

  &__password {
    display: inline-flex;
    flex-direction: column;
  }
  .verify-pwd-password {
    margin-top: rem(30px);
    &__controller {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: rem(10px);
    }
    &__error {
      padding-top: rem(20px);
    }
  }

  &__timer {
    display: inline-flex;
    align-items: center;
    font-size: rem(18px);
    line-height: rem(22px);
    text-transform: capitalize;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: rem(30px);
    fieldset {
      display: flex;
      flex-direction: column;
      margin-bottom: rem(20px);
      * + .input-wrapper {
        margin-top: rem(20px);
      }
      .error {
        line-height: 2;
        color: var(--status-error);
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      li {
        display: inline-flex;
        align-items: center;
      }
    }

    ul + button {
      margin-top: rem(20px);
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.verify-congratulation {
  text-align: center;

  img {
    width: rem(100px);
  }
  &__title {
    font-size: rem(30px);
    font-weight: 700;
  }
  &__subtitle {
    font-size: rem(18px);
    color: var(--grey);
  }

  * + &__subtitle {
    margin-top: rem(14px);
  }
  * + .button {
    margin-top: rem(20px);
  }
}
