@import '@/assets/styles/mixins';

.services {
	display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100dvh - 100px);
  &-gradient-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: rem(50px);
    padding-bottom: rem(42px);
		padding-left: rem(16px);
		padding-right: rem(16px);
    color: var(--white);
    border-radius: 10px;
    background: linear-gradient(180deg, #6071ff 0%, #9475ff 100%);

    .title {
			text-align: center;
			text-wrap: balance;
      line-height: rem(48px);
      font-size: rem(40px);
      font-weight: 600;
    }

    .search-input-group {
			margin-top: rem(20px);
			position: relative;
      display: flex;
      max-width: rem(720px);
      width: 100%;
      height: rem(42px);
      border-radius: 8px;
      background: #fff;
			padding-left: rem(47px);
			color: #bababa;

			.icon {
				position: absolute;
				top: 50%;
				left: rem(13px);
				transform: translateY(-50%);
			}

      input[type='search'] {
        width: calc(100% - 8px);
				border: initial;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

	&-list {
		position: relative;
		margin-top: rem(44px);
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
		grid-column-gap: rem(28px);
		grid-row-gap: rem(46px);
		overflow-y:  auto;
		flex: 1;
		&.has-scroll {
			padding-right: rem(16px);
		}
		&-item {
			display: grid;
			grid-template-columns: subgrid;
			justify-content: center;
			align-items: center;
			justify-items: center;
			align-content: center;
			text-align: center;
			min-height: rem(226px);
			min-width: rem(310px);
			border-radius: 10px;
			border: 1px solid rgba(106, 74, 227, 0.30);
			background: #FFF;
			transition: 0.4s ease all;
			&:hover {
				cursor: pointer;
				border-color: rgba(0, 218, 229, 0.40);
			}
			> * {
				max-width: rem(248px);
			}

			.title {
				color: #2C2348;
				font-size: rem(20px);
				font-weight: 600;
				line-height: rem(24px);
			}
			.description {
				text-wrap: balance;
				color: #696969;
				font-size: rem(14px);
				font-weight: 500;
				line-height: rem(17px);
				overflow: hidden;
				display: -webkit-box;
				-webkit-line-clamp: 3;
								line-clamp: 3; 
				-webkit-box-orient: vertical;
			}

			* + .title {
				margin-top: rem(20px);
			}
			* + .description {
				margin-top: rem(15px);
			}
		}
	}
}
