@import '@/assets/styles/mixins';

.registered-services {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: rem(10px);
  border: 1px solid #eff0f6;
  height: rem(320px);
  width: 100%;
  // overflow: hidden;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem(30px) rem(30px) 0;

  }
  &-content {
    position: relative;
    display: flex;
    flex: 1;
    height: rem(232px);
  }
  &-loading,
  &-nodata {
    display: flex;
    flex-direction: column;
    color: #8690a5;
    margin: auto;

    span: {
      font-size: rem(20px);
      font-weight: 600;
    }
  }

  &__title {
    white-space: nowrap;
    font-size: rem(22px);
    line-height: rem(26px);
    font-weight: 600;
    letter-spacing: -0.22px;
  }
  &__filter {
    margin-left: rem(40px);
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
    gap: rem(26px);
    .search-input-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: rem(10px);
      border-radius: 10px;
      background: #F9FBFF;
      color: #8690A5;
      height: rem(40px);
      max-width: rem(500px);
      width: 100%;
      padding-left: rem(20px);
      padding-right: rem(13px);
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.12px;
      input {
        display: inline-flex;
        border: initial;
        padding: initial;
        font-size: inherit;
        height: 40px;
        width: 100%;
        background-color: inherit;
        color: var(--black);
        &::placeholder {
          color: #8690A5;
        }
        &::-webkit-search-cancel-button {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .table {
    height: max-content;
    border: initial;
    &-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    &-body {
      overflow: auto;
      max-height: calc(rem(232px) - 54px);
      .tr {
        &:hover {
          background-color: rgba(88, 5, 250, 0.07);
        }
      }
      .td {
        display: flex;
        flex-direction: column;
        box-shadow: initial;
        height: 94px;
        justify-content: center;
        &:first-child {
          flex: 1;
        }
        &:not(:first-child) {
          align-items: center;
        }
      }
      .service {
        display: flex;
        gap: rem(34px);
        padding-left: rem(40);
        &__asset {
          width: rem(120px);
          flex-shrink: 0;
        }
        &__title {
          font-size: rem(18px);
          line-height: rem(26px);
          font-weight: 600;
          letter-spacing: -0.18px;
        }
        &__desc {
          margin-top: rem(6px);
          color: #8690A5;
          font-size: rem(12px);
          font-weight: 400;
          line-height: 1.2;
          letter-spacing: -0.12px;
        }
      }
      
    }
    .tr {
      &:has(.th) {
        border-bottom: 1px solid #EEE;
      }
      .th {
        justify-content: flex-start;
        color: #8690A5;
        font-size: rem(14px);
        line-height: 1;
        font-weight: 500;
        letter-spacing: -0.14px;
        padding-top: rem(20px);
        padding-left: rem(30px);
        padding-bottom: rem(20px);
        box-shadow: initial;
        &:first-child {
          flex: 1;
        }
      }
      
    }
  }
}
