@import '@/assets/styles/mixins';

.verify-status-info {
  display: flex;
  flex-direction: column;
	align-items: center;
	text-align: center;
	width: 100%;
  color: var(--black);
  background-color: #f8f8f8;
  padding: 12% ;
  border-radius: rem(18px);
	gap: 0.5em;
  .verify-status-info-wrapper {
    max-width: rem(400px);
  }
  &__title {
		text-wrap: balance;
    font-size: rem(38px);
  }
  &__description {
    text-wrap: balance;
    font-size: rem(20px);
    margin-top: rem(20px);
    color: var(--grey);
  }
  .button {
    margin-top: rem(20px);
  }
}

@media screen and (min-width: 992px) {
  .verify-status-info {
    border-radius: rem(30px);
    &__title {
      font-size: rem(52px);
    }
    &__description {
      font-size: rem(26px);
    }
  }
}
