@import '@/assets/styles/mixins';

.is-data-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #7a7a7a;
  margin: auto;
  z-index: 2;
  svg {
    animation: rotate 2s linear infinite;
  }
  span: {
    font-size: rem(20px);
    font-weight: 600;
  }
}