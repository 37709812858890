@import '@/assets/styles/mixins';

.edit-number-wrapper {
	display: flex;
  flex-direction: column;
  width: 50px;
  height: 75px;
  color: var(--black);
  border: 3px solid var(--grey);
  border-radius: rem(10px);
  overflow: hidden;
	&.is-changed {
		border-color: var(--blue);
	}
	&__input {
		display: inline-flex;
		justify-content: center;
		border: initial;
		color: inherit;
		outline: none;
		text-align: center;
		width: 100%;
		flex-grow: 1;
		padding: 0;
		font-weight: 700;
		font-size: 40px;
		line-height: 48px;
		transition: 0.3s ease all;
	}
}

@media screen and (min-width: 992px) {
  .edit-number-wrapper {
		width: 60px;
		height: 90px;
	}
}