@import '@/assets/styles/mixins';

.roles-management {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100dvh - 100px);
  &__back {
    position: absolute;
    top: 20px;
    .button {
      width: 40px;
      height: 40px;
      background-color: transparent;
      border: initial;
      color: #6071ff;
    }
  }
  &__title {
    color: #092e49;
    font-size: rem(28px);
    line-height: rem(33px);
    font-weight: 500;
    margin-bottom: rem(30px);
  }

  &-controls {
    display: flex;
    gap: rem(18px);
    > .button {
      flex-shrink: 0;
    }

    .button {
      border-radius: 20px;
      border: 3px solid #6071ff;
      background-color: #6071ff;
      color: var(--white);
      font-weight: 500;
      transition: 0.3s ease all;
      height: rem(44px);
      &:hover,
      &:active {
        background-color: var(--white);
        color: #6071ff;
        &:disabled {
          color: var(--white);
        }
      }
      &:disabled {
        border-color: var(--grey);
        background-color: var(--grey);
      }
      &:first-child {
        width: rem(120px);
      }
    }
  }
}
