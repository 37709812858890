@import '@/assets/styles/mixins';

.tokens-list {
  .file-information {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 50px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 50px;
    overflow: hidden;

    &__title {
      font-size: rem(24px);
      line-height: 1.2;
    }
    &__content {
      margin-top: rem(30px);
      overflow: auto;
    }

    pre code.hljs {
      overflow-x: initial;
    }
  }

  .table-wrapper {
    position: relative;
    overflow: auto;
    width: 100%;
    height: auto;
    min-height: rem(250px);
    max-height: rem(570px);
    margin-top: rem(40px);
    background-color: var(--white);
    container-type: inline-size;

    input[type='checkbox'] {
      width: rem(20px);
      height: rem(20px);
      border-radius: rem(2px);
      margin: initial;
      cursor: pointer;
    }

    .table-body--loading {
      filter: blur(10px);
    }

    table {
      table-layout: fixed;
      width: 100%;

      thead {
        tr {
          border-bottom: 1px solid #dfdfdf;
          th {
            &:not(th.select, th.status) {
              padding-top: 17px;
              padding-bottom: 10px;
              padding-left: 16px;
            }
            // th.select, th.status {
            // 	padding-top: 17px;
            // 	padding-bottom: 10px;
            //   text-align: center;

            // }
            color: #343a40;
            font-family: var(--font-family-Roboto);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
          }
        }
      }

      tbody {
        tr {
          border-top: 1px solid #dfdfdf;
          &:hover {
            background-color: rgba(88, 5, 250, 0.07);
          }
          td {
            &:not(td.select, td.status) {
              padding-top: 13px;
              padding-bottom: 14px;
              padding-left: 16px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &.status {
              text-align: center;
              .info-status {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                height: rem(24px);
                border-radius: rem(26px);
                padding: 0 rem(10px);
                font-size: rem(14px);
                font-weight: 500;
                color: var(--white);
                margin: auto;
                &--active {
                  background-color: #02cc54;
                }
                &--deprecated {
                  background-color: #e20000;
                }
                &--inactive {
                  background-color: #ffbf1b;
                }
              }
            }
            color: #4f4f4f;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
        }
      }

      td,
      th {
        &.select {
          width: rem(50px) !important;
          text-align: center;
        }
        &.tokenId {
          width: rem(177px);
        }
        &.tokenIdSource {
          width: rem(177px);
        }
        &.description {
          width: rem(230px);
        }
        &.accountIssuer {
          width: rem(180px);
        }
        &.accountId {
          width: rem(180px);
        }
        &.date {
          width: rem(160px);
        }
        &.type {
          width: rem(175px);
        }
        &.status {
          width: rem(200px);
          text-align: center;
        }
      }
    }
  }

  .table-pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: auto;
    background-color: var(--white);
    height: rem(60px);
    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: var(--white);

      height: rem(60px);
      width: rem(18px);
      z-index: 1;
    }
    &:before {
      left: rem(-18px);
      top: 0;
    }
    &:after {
      right: rem(-18px);
      top: 0;
    }

    &-info {
      font-size: rem(14px);
      line-height: rem(20px);
      font-style: normal;
      font-weight: 400;
    }

    > div {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      width: 200px;
    }

    display: flex;
    button {
      cursor: pointer;
      border: initial;
      width: rem(24px);
      height: rem(30px);
      color: var(--black);
      border-radius: rem(4px);
      padding: initial;
      background-color: #dfdfdf;
      &:hover {
        background-color: var(--grey);
      }
      &[disabled] {
        cursor: not-allowed;
        background-color: var(--grey);
        &:hover {
          background-color: var(--grey);
        }
      }
    }
    &-info {
      display: inline-flex;
      align-items: center;
    }
  }

  .popover-body {
    display: flex;
    flex-direction: column;
    gap: rem(10px);
    .popover-list {
      display: flex;
      flex-direction: column;
      gap: rem(4px);
      list-style: inside square;
      &__item {
        padding: rem(4px);
        transition: 0.4s ease all;
        &.active {
          background-color:rgba(88, 5, 250, 0.07)
        }
        &--MINTED {
          &::marker {
            color: #02cc54;
          }
        }
        &--DEPRECATED {
          &::marker {
            color: #e20000;
          }
        }
        &--INACTIVE {
          &::marker {
            color: #ffbf1b;
          }
        }
        &:hover {
          cursor: pointer;
          background-color: #dfdfdf;
        }
      }
    }
  }
}
