@import '@/assets/styles/mixins';

.is-nodata-available {
	display: flex;
  flex-direction: column;
	align-items: center;
  color: #7a7a7a;
  margin: auto;
  z-index: 2;
  span: {
    font-size: rem(20px);
    font-weight: 600;
  }
}