@import '@/assets/styles/mixins';

.dashboard-main {
  display: flex;
	flex-direction: column;
  flex: 1 1 auto;
	overflow: hidden;
	transition: 0.4s ease all;
	padding-right: 350px;
	&--wide {
		padding-right: 0;
	}
	> * {
		padding-left: rem(18px);
		padding-right: rem(18px);
	}

  &-header {
    display: flex;
    height: rem(80px);
    align-items: center;
    justify-content: flex-end;
		flex-shrink: 0;
		&__profile {
			display: inline-flex;
			align-items: center;
			color: #092E49;
			gap: rem(8px);

			font-size: rem(14px);
			font-weight: 600;
			letter-spacing: -0.14px;
		}
  }

	&-content {
		display: flex;
		flex-direction: column;
		flex: 1;
		overflow-y: auto;
	}

  &__title {
    font-size: rem(22px);
    line-height: rem(26px);
    font-weight: 600;
    letter-spacing: -0.22px;
  }
}
