@import '@/assets/styles/mixins';

.service-instances {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100dvh - 100px);
  &-controls {
    display: flex;
    gap: rem(18px);
    > .button {
      flex-shrink: 0;
    }

    .button {
      border-radius: 20px;
      border: 3px solid #6071ff;
      background-color: #6071ff;
      color: var(--white);
      font-weight: 500;
      transition: 0.3s ease all;
      height: rem(44px);
      &:hover,
      &:active {
        background-color: var(--white);
        color: #6071ff;
        &:disabled {
          color: var(--white);
        }
      }
      &:disabled {
        border-color: var(--grey);
        background-color: var(--grey);
      }
      &:first-child {
        width: rem(120px);
      }
    }
  }

  &__back {
    position: absolute;
    top: 20px;
    .button {
      width: 40px;
      height: 40px;
      background-color: transparent;
      border: initial;
      color: #6071ff;
    }
  }

  &__title {
    font-weight: 500;
    font-size: rem(22px);
    line-height: rem(26px);
    color: var(--black);
  }

  .service {
    display: grid;
    align-items: center;
    grid-template-columns: 60px 1fr;
    height: rem(95px);
    border-radius: rem(10px);
    border: 1px solid rgba(0, 218, 229, 0.4);
    background: #fff;
    gap: rem(18px);
    padding-left: rem(30px);
    padding-right: rem(30px);

    &-info {
      display: flex;
      flex-direction: column;
      gap: rem(6px);
      .title {
        color: #2c2348;
        line-height: rem(24px);
        font-size: rem(20px);
        font-weight: 600;
      }
      .description {
        color: #696969;
        line-height: rem(17px);
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  * + &-controls {
    margin-top: rem(28px);
  }
  * + &__title {
    margin-top: rem(46px);
  }
}
