@import '@/assets/styles/mixins';

.tokens-list {
  display: flex;
  flex-direction: column;
  height: calc(100vh - rem(80px));

  * + &-controls {
    margin-top: rem(46px);
  }
  &-controls {
    display: flex;
    gap: rem(20px);
    margin-top: rem(60px);

    button {
      &:first-child {
        width: rem(120px);
      }
    }
  }
  &-loading {
    display: flex;
    flex-direction: column;
    color: #7a7a7a;
    margin: auto;
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    svg {
      animation: rotate 2s linear infinite;
    }
    span: {
      font-size: rem(20px);
      font-weight: 600;
    }
  }
  &-nodata {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    > * + * {
      margin-top: rem(30px);
    }
    span {
      font-size: rem(30px);
      font-weight: 500;
      color: #7a7a7a;
    }
  }

  &__title {
    font-size: rem(28px);
    line-height: rem(34px);
    font-weight: 500;
    color: #092e49;
  }
  .button {
    border-radius: 20px;
    border: 3px solid #6071ff;
    background-color: #6071ff;
    color: var(--white);
    font-weight: 500;
    transition: 0.3s ease all;
    height: rem(44px);
    &:hover,
    &:active {
      background-color: var(--white);
      color: #6071ff;
      &:disabled {
        color: var(--white);
      }
    }
    &:disabled {
      border-color: var(--grey);
      background-color: var(--grey);
    }
  }
}
