@import '@/assets/styles/mixins';

.create-role, .duplicate-role {
  min-width: rem(620px);
  max-width: rem(746px);
  padding: rem(40px);
  display: flex;
  flex-direction: column;
  flex: 1;

  .title {
    color: #000;
    font-size: rem(24px);
    line-height: rem(30px);
    font-weight: 500;
  }

  .form-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    .is-data-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 11;
    }
    &:before {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.9);
      z-index: 10;
    }
    &--loading {
      &:before {
        content: '';
      }
    }

    .button[type='submit'] {
      border-radius: 20px;
      border: 3px solid #6071ff;
      background-color: #6071ff;
      color: var(--white);
      font-weight: 500;
      transition: 0.3s ease all;
      height: rem(44px);
      margin: auto;
      margin-top: rem(60px);
      &:hover,
      &:active {
        background-color: var(--white);
        color: #6071ff;
        &:disabled {
          color: var(--white);
        }
      }
      &:disabled {
        border-color: var(--grey);
        background-color: var(--grey);
      }
      &:first-child {
        width: rem(120px);
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: rem(30px);
    fieldset {
      .create-role-form-list {
        &__item {
          display: flex;
          flex-direction: column;
          position: relative;

          label {
            color: #8690a5;
            font-size: rem(14px);
            font-weight: 700;
            line-height: rem(18px);
            + .input-wrapper {
              margin-top: rem(8px);
            }
            + * {
              margin-top: rem(8px);
            }
          }
        }

        .input-wrapper {
          input {
            height: rem(30px);
            border-radius: initial;
            font-size: rem(14px);
            border: 1px solid #ddd;
          }
        }

        > li + li {
          margin-top: rem(24px);
        }
      }
    }
  }
}
