@import '@/assets/styles/mixins';

.service-test {
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
	flex: 1;
  .title {
    color: rgba(6, 17, 10, 0.5);
    line-height: rem(36px);
    font-size: rem(30px);
    font-style: normal;
    font-weight: 500;
    span {
      color: #6071ff;
    }
  }

  .description {
    color: rgba(6, 17, 10, 0.5);
    line-height: rem(30px);
    font-size: rem(25px);
    font-style: normal;
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .description + pre {
    margin-top: rem(50px);
    > code {
      background: #f4f4f4;
      font-family: var(--font-family-Inconsolata);
      max-height: 120px;
    }
	}

	.form {
    display: flex;
    flex-direction: column;
    margin-top: rem(40px);
		overflow-y: auto;
  	flex: 1 1;
	
		&-list {
			display: flex;
			flex-direction: column;
			gap: rem(26px);
			&--step2 {
				.form-list__item {
					background-color: initial;
					.responseToken {
						display: flex;
						align-items: center;
						padding: 0 rem(12px) 0;
						height: rem(50px);
						background-color: #F4F4F4;
						font-size: rem(14px);
						text-decoration: underline;
						color: #1E75E5;
						font-weight: 700;
					}
					.responseContent {
						padding: rem(10px) rem(12px);
						background-color: #F4F4F4;
						color: #1E75E5;
					}
				}
			}
			&__item {
				display: flex;
				flex-direction: column;
				position: relative;
				gap: rem(4px);
				min-height: rem(65px);
				border-radius: 10px;
				background-color: #F4F4F4;
				border: 2px solid transparent;
				padding-left: rem(12px);
				padding-right: rem(20px);
				padding-top: rem(6px);
				transition: 0.4s  ease all;
				&:has(input[type="text"]:focus,
				input[type="checkbox"]:focus, select:checked) {
					background-color: #fff;
					border: 2px solid #1E75E5;
				}

				&.onFocus {
					background-color: #fff;
					border: 2px solid #1E75E5;
				}

				label {
					color: rgba(0, 0, 0, 0.50);
					font-size: rem(14px);
					line-height: rem(17px);
					font-weight: 500;
				}

				input[type="checkbox"] {
					display: inline-flex;
					width: fit-content;
				}

				input[type="text"] {
					position: absolute;
					border: initial;
					background-color: transparent;
					margin: 0;
					width: 100%;
					height: 100%;
					left: 0;
					right: 0;
					top: 0;
					padding-left: rem(12px);
				}

				select {
					position: absolute;
					height: 100%;
				  width: -webkit-fill-available;
					border: initial;
					background-color: transparent;
					margin: 0;
				}
			}
		}
  }
	.form + .btn-wrapper {
		display: flex;
		margin-left: auto;
		margin-right: auto;
		margin-top: rem(32px);
		margin-bottom: rem(32px);
		gap: rem(18px);
		.button {
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			border-radius: 20px;
			background: #6071FF;
			border: 2px solid #6071FF;
			height: rem(44px);
			width: auto;
			padding-left: rem(40px);
			padding-right: rem(40px);
			&:hover {
				color: #6071FF;
				background-color: var(--white);
			}
		}
	}

	.is-data-loading {
		span {
			text-wrap: balance;
			text-align: center;
			max-width: rem(300px);
		}
	}
}
