@import '@/assets/styles/mixins';

.dropdown {
  position: relative;
  display: flex;
	&-header {
		cursor: pointer;
		display: flex;

		&--select {
			align-items: center;
			justify-content: space-between;
			min-width: rem(140px);
			height: rem(40px);
			padding: 0 rem(16px);
			gap: rem(20px);
			border-radius: 10px;
			background: #F9FBFF;
			color: #7E7E7E;
			font-size: rem(12px);
			font-weight: 400;
			line-height: 1;
			letter-spacing: -0.12px;
		}
	}
  &-list {
    position: absolute;
    top: calc(100% + 10px);
		right: 0;
    border-radius: 10px;
    background-color: var(--white);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 1;
		overflow: hidden;
		&__item {
			cursor: pointer;
      display: flex;
      align-items: center;
			height: rem(38px);
			min-width: rem(190px);
      font-size: rem(14px);
      font-weight: 500;
      line-height: 1;
      letter-spacing: -0.14px;
			transition: 0.3s ease all;
			button {
				display: flex;
				align-items: center;
				width: 100%;
				height: 100%;
				padding-left: rem(20px);
				padding-right: rem(12px);
				background: transparent;
				outline: none;
				border: initial;
			}
			&:hover {
				background: rgba(88, 5, 250, 0.07);
			}
    }
  }
}
