@import '@/assets/styles/mixins';

.error-notification {
	display: inline-flex;
  gap: rem(10px);
  &__info {
    display: inline-flex;
    flex-direction: column;
  }
  &__title,
  &__description{
    font-size: rem(18px);
    line-height: 1;
  }
  &__description{
    color: var(--grey);
  }
	.icon {
		width: rem(36px);
		height: rem(36px);
	}
}