@import '@/assets/styles/mixins';

.popover {
  position: relative;
  color: var(--black);
  &__close {
    position: absolute;
    cursor: pointer;
    padding: initial;
    background-color: initial;
    border: initial;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: rem(16px);
    height: rem(16px);
    top: rem(4px);
    right: rem(4px);
  }
  &-trigger {
    cursor: pointer;
    display: inline-flex;
		align-items: center;
		justify-content: center;
    position: relative;
    gap: rem(10px);
		height: rem(24px);
  }

  &-content {
		position: absolute;
    visibility: visible;
    min-width: max-content;
    border-radius: rem(10px);
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.25);

		max-width: 400px;

    background-color: var(--white);
    inset: 30px auto auto 0px;
    margin: 0px;
    z-index: 10;
  }

  &-header {
    padding-inline-start: rem(12px);
    padding-inline-end: rem(24px);
    padding-top: rem(8px);
    padding-bottom: rem(8px);
    border-bottom: 1px solid var(--grey);
  }
  &-body {
    padding-inline-start: rem(12px);
    padding-inline-end: rem(24px);
    padding-top: rem(8px);
    padding-bottom: rem(8px);
  }
}
