@import '@/assets/styles/mixins';

.token-create-step {
  display: flex;
  &--upload {
    display: flex;
    flex-direction: column;
    width: 100%;
    .dropzone {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: rem(10px);
      color: #202124;

      &__icon {
        height: rem(50px);
      }
      &__title {
        font-size: rem(20px);
        line-height: rem(32px);
      }
      &__or {
        font-size: rem(16px);
        line-height: rem(20px);
      }

      button {
        padding: 7px 15px;
        height: rem(30px);
        background-color: var(--white);
        font-size: rem(16px);
        width: initial;
        line-height: 1;
        color: #6071ff;
        border-radius: 20px;
        border: 2px solid #6071ff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
      }
    }

    .progressbar {
      display: flex;
      gap: rem(8px);
      margin-top: rem(50px);
      &--init {
        color: #14d6e2;
        .progressbar-content {
          .progress-bar {
            background: #14d6e2;
          }
        }
      }
      &--done {
          color: #6071ff;
        .progressbar-content {
          .progress-bar {
            background: #6071ff;
          }
        }
      }
      &--error {
        color: #e20000;
        .progressbar-content {
          .progress-bar {
            background: #e20000;
          }
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        span {
          display: inline-flex;
          text-wrap: nowrap;
          font-size: rem(16px);
          line-height: rem(20px);
          margin-bottom: rem(6px);
        }
        @for $i from 0 through 100 {
          .progress progress[value='#{$i}'] {
            & + .progress-value:before {
              // content: '#{$i}%';
              content: '';
            }
            & ~ .progress-bg .progress-bar {
              width: $i * 1%;
            }
          }
        }

        .progress {
          font:
            12px Arial,
            Tahoma,
            sans-serif;
          position: relative;
          overflow: hidden;
          progress {
            position: absolute;
            width: 0;
            height: 0;
            overflow: hidden;
            left: -777px;
          }
        }

        .progress-value {
          color: #333;
          display: block;
          line-height: 21px;
          text-align: center;
        }

        .progress-bg {
          background: #e6e9ed;
          position: relative;
          height: 8px;
          border-radius: 5px;
          overflow: hidden;
        }

        @-webkit-keyframes progress_bar {
          0% {
            background-position: 0 0;
          }
          100% {
            background-position: -40px 0;
          }
        }
        @-moz-keyframes progress_bar {
          0% {
            background-position: 0 0;
          }
          100% {
            background-position: -40px 0;
          }
        }
        @keyframes progress_bar {
          0% {
            background-position: 0 0;
          }
          100% {
            background-position: -40px 0;
          }
        }

        .progress-bar {
          overflow: hidden;
          background: #ac92ec;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          -webkit-transition: width 1s linear;
          -moz-transition: width 1s linear;
          -ms-transition: width 1s linear;
          transition: width 1s linear;
          &:after {
            background-size: 40px 40px;
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
      &__icon {
        flex-shrink: 0;
        width: rem(24px) !important;
        height: rem(24px);
      }
    }
  }
}

@keyframes progress_bar {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -40px 0;
  }
}
