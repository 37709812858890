@import '_normalize';
@import '_fonts.scss';
@import '_mixins.scss';

:root {
  --font-family: 'SF Pro Display', sans-serif;
  --font-family-Inconsolata: 'Inconsolata', sans-serif;
  --font-family-Roboto: 'Roboto', sans-serif;
  --font-size: 16px;

  --black: #000000;
  --white: #ffffff;
  --grey: #7a7a7a;
  --purple: #5805fa;
  --cyan: #6dffff;
  --blue: #1e75e5;
  --status-success: #459b8f;
  --status-error: #ff7474;
}

body {
  line-height: 1.2;
  min-width: rem(320px);
  width: 100dvw;
  height: 100dvh;
  position: relative;
  overflow-x: hidden;
  font-family: var(--font-family);
  color: var(--black);
  font-size: var(--font-size);
  background-color: var(--white);
  &.no-scroll {
    overflow: hidden;
  }
}

#root {
  display: flex;
  flex-direction: column;
  width: 100dvw;
  height: 100dvh;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.table {
  border: 1px solid #424242;
  // width: 100%;
  // display: flex;
  // flex-direction: column;
}

.tr {
  display: flex;
  // width: fit-content;
}
.th,
.td {
  box-shadow: inset 0 0 0 1px #424242;
}
.th {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
}

* {
  &::selection {
    background: transparent;
    color: rgba(6, 17, 10, 0.5);
  }
  @media (min-width: 1024px) {
    scrollbar-color: rgba(6, 17, 10, 0.5);
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(6, 17, 10, 0.5);
      border-radius: 4px;
      box-shadow: none;
      opacity: 1;
      &:hover {
        background-color: rgba(6, 17, 10, 0.5);
        opacity: 1;
      }
      &:active {
        opacity: 1;
        background-color: rgba(6, 17, 10, 0.5);
      }
      &:focus {
        opacity: 1;
        background-color: rgba(6, 17, 10, 0.5);
      }
    }
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(217, 217, 217, 0.7);
  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 96dvw;
    max-height: 80dvh;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #eaeaea;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    @media screen and (min-width: 768px) {
      max-width: 80dvw;
      max-height: 80dvh;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: auto;
    overflow-y: auto;
  }
  &__close-btn {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: initial;
    border: initial;
    background-color: transparent;
    inset-inline-end: 12px;
    top: rem(12px);
    width: rem(24px);
    height: rem(24px);
    color: #878181;
    transition: 0.4s ease all;
    &:hover {
      color: var(--black);
    }
  }
}

.modal {
  &__content {
    .token-create {
      .token-create-content {
        width: 948px;
        padding: 40px;
        .token-create-step-list {
          &__index {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: rem(35px);
            height: rem(35px);
            flex-shrink: 0;
            border-radius: 50%;
            color: #9475ff;
            border: 1px solid #9475ff;
            &:before {
              position: absolute;
              content: '';
              width: 2px;
              background-color: #9475ff;
              height: 33px;
              top: 100%;
              left: 50%;
              // transform: translateX(-50%);
            }
            svg {
              margin: auto;
            }
          }
          &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: rem(14px);
            padding-right: rem(24px);
            border-radius: rem(10px);
            height: rem(56px);
            width: 100%;
            flex-shrink: 0;
            &:last-child {
              .token-create-step-list__index::before {
                content: none;
              }
            }
    
            svg {
              width: rem(24px) !important;
              height: rem(24px);
              flex-shrink: 0;
            }
    
            &.isActive {
              background-color: rgba(148, 117, 255, 0.2);
              .token-create-step-list__index {
                color: var(--white);
                background-color: #9475ff;
              }
            }
            &.isDone {
              .token-create-step-list__index {
                color: var(--white);
                background-color: #9475ff;
              }
            }
          }
          * + &__text {
            margin-left: rem(27px);
          }
        }
      }
    }
  }
}

.confirmation-modal {
  &--info {
    color: #5805fa;
  }
  &--delete {
    color: #E20000;
  }
}

.confirmation-modal, .creation-process-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 502px;
  height: 360px;
  padding-top: 50px;
  &-footer {
    display: flex;
    margin-top: auto;
    width: 100%;
    border-top: 1px solid #eaeaea;
    button {
      flex: 1;
      height: 78px;
      border-radius: initial;
      background-color: transparent;
      font-size: 28px;
      color: #4f4f4f;
      font-weight: 500;
      &:hover,
      &:focus,
      &:active {
        background-color: initial;
      }
      &:hover {
        color: inherit;
      }

      &:first-child {
        border-right: 1px solid #eaeaea;
      }
    }
  }

  &__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: inherit;
  }

  &__title,
  &__description {
    text-wrap: balance;
    text-align: center;
  }
  &__title {
    text-align: center;
    font-size: 28px;
    line-height: 33px;
    font-weight: 600;
    color: #4f4f4f;
    max-width: 300px;
  }
  &__description {
    font-size: 20px;
    line-height: 24px;
    color: #7a7a7a;
    font-weight: 500;
  }

  * + &__title {
    margin-top: rem(12px);
  }
  * + &__description {
    margin-top: rem(12px);
  }
}