@import '@/assets/styles/mixins';

.search-token {
	.search {
		border-radius: 4px;
		border: 1px solid #1e75e5;
		background: #eff6ff;
		width: 100%;
		font-size: 16px;
		padding: 12px 10px;
		&::placeholder {
			color: rgba(124, 124, 124, 0.5);
		}
	}
  &-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    padding: rem(14px);
    &__content {
      display: flex;
      flex: 1;

      .is-nodata-available {
        text-align: center;
        margin: auto;
        width: 210px;
      }
    }
    .create-token {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: rem(4px);
      background-color: transparent;
      padding: rem(8px) rem(10px);
      border: initial;
      height: rem(34px);
      line-height: 10.5px;
      gap: rem(10px);
      color: #4399ff;
      font-size: rem(12px);
      font-weight: 600;
      &:hover {
        background-color: #eff6ff;
      }
    }

    .radio-group {
      width: 100%;
      border-radius: rem(4px);
      background: #fff;
      max-height: rem(186px);
      overflow-y: auto;
      &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 0;

        .radio {
          gap: rem(10px);
          height: rem(34px);
          border-radius: rem(4px);
          padding: rem(8px) rem(10px);
          font-size: 12px;
          color: rgba(6, 17, 10, 0.5);
          &:has(.radio__input:checked) {
            background: #eff6ff;
          }
          &__label {
            font-size: inherit;
            color: inherit;
          }
          &__input {
            border-radius: rem(4px);
            width: rem(16px);
            height: rem(16px);
            &::before {
              margin: 1px;
              border-radius: rem(2px);
              width: rem(10px);
              height: rem(10px);
            }
          }
        }
      }
    }
  }
}
