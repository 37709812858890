@import '@/assets/styles/mixins';

.chat-ai {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: rem(325px);
  min-height: 100dvh;
  height: 100%;
  flex-shrink: 0;
  right: 0;
  bottom: 0;
  background-color: var(--white);
  font-size: rem(18px);
  transition: 0.4s ease all;
  &--closed {
    right: -325px;
  }
  &-show-handler {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    cursor: pointer;
    border: initial;
    left: rem(-20px);
    top: rem(80px);
    font-size: 14px;
    font-weight: 700;
    width: rem(20px);
    height: rem(180px);
    clip-path: polygon(0% 0%, 100% 10%, 100% 90%, 0% 100%);
    background-color: #5805fa;
    color: var(--white);
    z-index: 2;
  }
  &-divider {
    width: 100%;
    height: rem(1px);
    background-color: #e6e6e6;
  }

  &-header {
    padding: rem(17px) rem(12px) rem(11px);
    &__name {
      font-weight: 700;
    }
  }
  &-helper {
    text-wrap: balance;
    padding: rem(14px);
    font-family: var(--font-family-Inconsolata);
    color: #5805fa;
  }
  &-message-sender {
    display: flex;
    align-items: center;
    margin-top: auto;
    gap: rem(5px);
    padding: rem(18px) rem(8px);
    margin-top: rem(12px);
    background-color: #dce8ff;
    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: rem(34px);
      height: rem(34px);
      border-radius: 50%;
      background-color: #4399ff;
      color: var(--white);
      border: initial;
      padding: initial;
    }

    .input-wrapper {
      input {
        border-radius: rem(30px);
        font-size: rem(15px);
        border-color: var(--white);
      }
    }
  }
}

.messages-list {
  display: flex;
  flex-direction: column;
  padding: rem(20px) rem(15px);
  height: 100%;
  width: 100%;
  overflow: auto;
  gap: rem(24px);

  .loading {
    justify-content: flex-start;
  }

  .message-wrapper {
    display: flex;
    width: 100%;
  }
  .message {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: rem(12px) rem(10px) rem(24px);
    max-width: 90%;
    font-size: rem(14px);
    line-height: 1.2;
    &--right {
      margin-left: auto;
      border-radius: 10px 10px 0px 10px;
      background-color: #4399ff;
      color: var(--white);
    }
    &--left {
      border-radius: 10px 10px 10px 0px;
      background-color: #dce8ff;
      color: var(--black);
    }
  }
}
