@import '@/assets/styles/mixins';

.token-create-step {
	display: flex;
	&--description {
		form {
			width: 100%;
			legend {
				position: relative;
				background: #fff;
				font-size: rem(10px);
				color: #3367D6;
				top: 10px;
				left: 14px;
				padding: 4px;
			}
		}
		textarea {
			width: 100%;
			height: rem(230px);
			border: 1px solid #3367D6;
			resize: none;
			padding: rem(10px);
		}
	}
}