@import '@/assets/styles/mixins';

.total-tokens {
  display: flex;
  flex-direction: column;
  padding: rem(30px);
  background-color: var(--white);
  border-radius: rem(10px);
  border: 1px solid #eff0f6;
  height: auto;
  width: 100%;
  &-header {
    display: flex;
    justify-content: space-between;
    justify-content: center;
  }
  &-content {
    position: relative;
    display: flex;
    flex: 1;
  }
  &-loading,
  &-nodata {
    display: flex;
    flex-direction: column;
    color: #8690a5;
    margin: auto;

    span: {
      font-size: rem(20px);
      font-weight: 600;
    }
  }

  &__title {
    font-size: rem(22px);
    line-height: rem(26px);
    font-weight: 600;
    letter-spacing: -0.22px;
  }
}

@container overview (min-width: 600px){
  .total-tokens{
    &-header {
      justify-content: flex-start;
    }
  }
}

@container overview (min-width: 1050px){
  .total-tokens {
    height: rem(330px);
    min-width: rem(424px);
    width: calc(45% - rem(5px));
  }
}