@import '@/assets/styles/mixins';

.input-wrapper {
  position: relative;
  display: inline-flex;
  width: 100%;
  max-width: rem(700px);
  input {
    appearance: none;
    width: 100%;
    padding: 0 16px;
    height: 50px;
    border: 1px solid var(--grey);
    color: inherit;
    outline: none;
    font-size: 20px;
    border-radius: 10px;
    background: var(--white);
    caret-color: var(--black); //secondary Light
    transition: 0.3s ease all;
    &::placeholder {
      color: var(--grey);
    }
    &:not(:placeholder-shown) {
      & + label {
        padding: rem(2px);
        font-size: rem(12px);
        line-height: rem(16px);
        transform: translate3d(16px, -10px, 0);
      }
    }
    &:hover {
      background: rgba(255, 255, 255, 0.16);
    }
    &:focus {
      background: rgba(255, 255, 255, 0.16);
      &::placeholder {
      }
      & + label {
        color: var(--grey);
        background: var(--white);
        padding: rem(2px);
        font-size: rem(12px);
        line-height: rem(16px);
        transform: translate3d(16px, -10px, 0);
      }
    }
  }

  label {
    position: absolute;
    top: -1px;
    left: 0;
    margin: 0;
    transform-origin: 0;
    transform: translate3d(20px, calc((50px - 20px) / 2), 0);
    font-size: 20px;
    line-height: 1;
    color: var(--grey);
    background: var(--white);
    transition: 0.3s ease all;
  }

  button.button {
    position: absolute;
    right: rem(10px);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: rem(26px);
    height: rem(26px);
    border-radius: initial;
    background-color: transparent;
    border: initial;
    transition: 0.3s ease all;
    color: var(--grey);
  }
}
