@import '@/assets/styles/mixins';

.service-create {
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  .title {
    color: rgba(6, 17, 10, 0.5);
    line-height: rem(36px);
    font-size: rem(30px);
    font-style: normal;
    font-weight: 500;
    span {
      color: #6071ff;
    }
  }

  .description {
    color: rgba(6, 17, 10, 0.5);
    line-height: rem(30px);
    font-size: rem(25px);
    font-style: normal;
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .description + pre {
    margin-top: rem(50px);
    > code {
      background: #f4f4f4;
      font-family: var(--font-family-Inconsolata);
      max-height: 120px;
    }
  }

  .service-create-instance {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    &-content {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: rem(50px);
      gap: rem(30px);
      &__asside {
        display: flex;
        flex-direction: column;
        max-width: rem(480px);
      }
      &__title {
        margin-top: rem(8px);
        margin-bottom: rem(50px);
        color: #092e49;
        line-height: rem(36px);
        font-size: rem(30px);
        font-weight: 500;
      }
  

      code {
        background: #f4f4f4;
        font-family: var(--font-family-Inconsolata);
        max-height: 120px;
      }
    }

    &-step-list {
      display: flex;
      flex-direction: column;
      gap: rem(10px);
      min-width: rem(300px);
      color: #092e49;
      font-size: 18px;
      font-weight: 500;
      // padding-left: rem(45px);
      &__index {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(35px);
        height: rem(35px);
        flex-shrink: 0;
        border-radius: 50%;
        color: #9475ff;
        border: 1px solid #9475ff;
        &:before {
          position: absolute;
          content: '';
          width: 2px;
          background-color: #9475ff;
          height: 33px;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: rem(14px);
        padding-right: rem(24px);
        border-radius: rem(10px);
        height: rem(56px);
        width: 100%;
        flex-shrink: 0;
        &:last-child {
          .service-create-instance-step-list__index::before {
            content: none;
          }
        }

        svg {
          width: rem(24px) !important;
          height: rem(24px);
          flex-shrink: 0;
        }

        &.isActive {
          background-color: rgba(148, 117, 255, 0.2);
          .service-create-instance-step-list__index {
            color: var(--white);
            background-color: #9475ff;
          }
        }
        &.isDone {
          .service-create-instance-step-list__index {
            color: var(--white);
            background-color: #9475ff;
          }
        }
      }
      * + &__text {
        margin-left: rem(27px);
      }
    }

    &-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: rem(380px);
      min-height: rem(320px);
      background-color: var(--white);
      border: 1px solid rgba(0, 0, 0, 0.22);
      &-header {
        text-align: center;
        color: #202124;
        font-weight: 400;
        font-family: var(--font-family-Roboto);
        padding-top: rem(32px);
        padding-bottom: rem(18px);
        &__title {
          font-size: rem(24px);
          line-height: rem(32px);
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        flex: 1;

        gap: rem(12px);
 

        &__description {
          padding: 0 16px 16px;
          display: flex;
          flex: 1;
          form {
            width: 100%;
            legend {
              position: relative;
              background: #fff;
              font-size: rem(10px);
              color: #3367D6;
              top: 10px;
              left: 14px;
              padding: 4px;
            }
          }
          fieldset {
            height: 100%;
          }
          textarea {
            width: 100%;
            height: 100%;
            border: 1px solid #3367D6;
            resize: none;
            padding: rem(10px);
          }
        }
      }
    }

    &-navigate {
      display: flex;
      justify-content: center;
      margin-top: 60px;
      width: 100%;
      gap: 18px;
      .button {
        border: initial;
        border-radius: 20px;
        border: 2px solid #6071ff;
        height: 44px;
        font-family: var(--font-family-Roboto);
        font-size: rem(18px);
        background-color: transparent;
        color: #6071ff;
        width: initial;
        padding: 0 rem(40px);
        &[disabled] {
          border: 2px solid rgba(134, 144, 165, 0.6);
          background-color: rgba(134, 144, 165, 0.6);
          color: var(--white);
        }
      }
    }
  }


}
