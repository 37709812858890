@import '@/assets/styles/mixins';

.token-create-step {
	display: flex;
	&--type {
		flex-direction: column;
		gap: rem(12px);
	}

	input[type="text"] {
		padding: rem(12px) rem(10px);
		border-radius: rem(4px);
		border: rem(1px) solid #1E75E5;
		background: #EFF6FF;
		font-size: rem(16px);
		color: #7C7C7C;
		&::placeholder {
			color: #7C7C7C80;
		}
		&[disabled] {
			cursor: not-allowed;
		}
	}

	.radio-group {
		padding: rem(14px);
		border-radius: rem(4px);
		background: #FFF;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);
		max-height: rem(186px);
		overflow-y: auto;
		&__wrapper {
			display: flex;
			flex-direction: column;
			gap: 0;

			.radio {
				gap: rem(10px);
				height: rem(34px);
				border-radius: rem(4px);
				padding: rem(8px) rem(10px);
				font-size: 12px;
				color: #06110A;
				&:has(.radio__input:checked) {
					background: #EFF6FF;
				}
				&__input {
					border-radius: rem(4px);
					width: rem(16px);
					height: rem(16px);
					&::before {
						margin: 1px;
						border-radius: rem(2px);
						width: rem(10px);
						height: rem(10px);
					}
				}
			}
		}
	}
}