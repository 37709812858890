@import '@/assets/styles/mixins';

.management {
  height: calc(100dvh - 80px);

  &__title {
    font-size: rem(28px);
    line-height: rem(34px);
    font-weight: 500;
    color: #092e49;
  }

  * + .management-list {
    margin-top: rem(50px);
    padding-left: rem(16px);
    padding-right: rem(16px);
  }

  .management-list {
    display: flex;
    flex-direction: column;
    gap: rem(34px);
	
		&__icon {
			position: absolute;
			display: flex;
			left: rem(35px);
			&-status {
				position: absolute;
				z-index: 1;
				&--allowed {
					top: 15px;
					left: calc(100% - 2px);
				}
				&--denied {
					top: 25px;
					left: calc(100% - 7px);
				}
			}
		}

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: rem(16px);
      height: rem(95px);
      flex-shrink: 0;
      border-radius: rem(20px);
      background-color: var(--white);
      box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.25);
    }
    &__info {
      display: flex;
      flex-direction: column;
      gap: rem(3px);
      padding-left: rem(103px);
    }
    &__title {
      line-height: rem(30px);
      font-size: rem(24px);
      color: #6070ff;
    }
    &__description {
      line-height: rem(20px);
      font-size: rem(16px);
      color: #31364a;
    }

    .icon {
      position: absolute;
      top: 50%;
      left: rem(34px);
      transform: translateY(-50%);
      &--tokenListing {
        width: rem(54px);
        height: rem(46px);
      }
      &--analytics {
        width: rem(44px);
        height: rem(50px);
      }
    }
  }
}
