@import '@/assets/styles/mixins';

.stepper {
	display: inline-flex;
  flex-direction: column;
	color: inherit;
	&__info {
		font-size: rem(18px);
		font-weight: 500;
	}
	* + &__info {
		margin-top: rem(16px);
	}
}

.stepper-list {
	display: flex;
	gap: rem(13px);
	&__item {
		display: inline-flex;
		width: rem(44px);
		height: rem(10px);
		border-radius: rem(20px);
		background-color: rgba(88, 5, 250, 0.20);
		&.active {
			background-color: var(--purple);
		}
	}
}