.button {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
	padding: initial;
  font-size: 22px;
  font-weight: 700;
  width: 200px;
  height: 60px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: var(--purple);
  color: var(--white);
  transition: 0.4s ease all;

  &:hover,
  &:active {
    background-color: color-mix(in srgb, var(--purple), #000 30%);
  }
  &:focus {
    background-color: color-mix(in srgb, var(--purple), #000 30%);
  }
  &[disabled] {
		cursor: not-allowed;
    background-color: var(--grey);
  }
  &--link {
    &[disabled] {
      background-color: initial;
      color: var(--grey);
      &:hover,
      &:active {
        background-color: initial;
        color: var(--grey);
      }
    }
    &:hover,
    &:active {
      background-color: initial;
      color: color-mix(in srgb, var(--purple), #000 30%);
    }
    &:focus {
      background-color: initial;
    }
    width: initial;
    height: initial;
    font-size: initial;
    background: initial;
    color: var(--purple);
    text-decoration: underline;
  }
}
