@import '@/assets/styles/mixins';

.multiselect {
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: rem(5px);
  }

  &-container {
    position: relative;
    display: flex;
    min-height: rem(57px);
    flex-shrink: 0;
    border-radius: 0.8px;
    border: 1px solid #ddd;
    background-color: #fff;
    padding: rem(11px) rem(32px) rem(11px) rem(16px);

    &-button {
      cursor: pointer;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      border: initial;
      padding: initial;
      flex-shrink: 0;
      background-color: var(--white);
      width: rem(24px);
      height: rem(24px);
      right: 0;
      top: 0;
      color: #8690a5;
    }
  }

  &-dropdown {
    max-height: rem(200px);
    overflow: auto;
    border-radius: 0 0 20px 20px;
    border: 1px solid #ddd;
    background: #fff;
    max-height: rem(200px);
    padding: rem(20px) rem(20px) rem(10px) rem(5px);

    ul {
      li {
        button {
          display: flex;
          align-items: center;
          cursor: pointer;
          border: initial;
          gap: rem(12px);
          width: 100%;
          height: rem(38px);
          font-size: rem(18px);
          font-weight: 500;
          background-color: var(--white);
          
          &:not([disabled]) {
            svg {
              color: #4399FF;
            }
          }

          &:hover:not([disabled]) {
            background-color: #5805FA12;
          }
        }
      }
    }
  }

  &-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: rem(15px) rem(21px);
    &__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #bababa;
      flex-shrink: 0;
      padding-left: rem(10px);
      padding-right: rem(5px);
      height: rem(32px);
      color: #000;
      font-size: rem(14px);
      font-weight: 600;
    }
    &__remove {
      cursor: pointer;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      background-color: var(--white);
      width: rem(10px);
      height: rem(10px);
      right: rem(-5px);
      top: rem(-5px);
      z-index: 1;
      color: #bababa;
      transition: 0.4s ease all;
      svg {
        width: rem(8px) !important;
        height: rem(8px) !important;
      }

      &:hover {
        color: var(--black);
        transform: rotate();
      }
    }
  }
}
