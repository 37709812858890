@import '@/assets/styles/mixins';

.transaction-activity {
  display: flex;
  flex-direction: column;
  padding: rem(30px);
  background-color: var(--white);
  border-radius: rem(10px);
  border: 1px solid #eff0f6;
  height: auto;
  width: 100%;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column; 
    gap: rem(20px);
  }
  &-content {
    position: relative;
    display: flex;
    flex: 1;
  }
  &-loading,
  &-nodata {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #8690a5;
    margin: auto;

    span: {
      font-size: rem(20px);
      font-weight: 600;
    }
  }

  &__title {
    font-size: rem(22px);
    line-height: rem(26px);
    font-weight: 600;
    letter-spacing: -0.22px;
  }
}

@container overview (min-width: 600px){
  .transaction-activity {
    &-header {
      flex-direction: initial; 
      gap: initial;
    }
  }
}

@container overview (min-width: 1050px){
  .transaction-activity {
    height: rem(330px);
    min-width: rem(570px);
    width: calc(55% - rem(5px));
  }
}