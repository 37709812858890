@import '@/assets/styles/mixins';

.service-instance-modal {
  &--edit {
    padding: rem(60px) rem(100px);
  }

  .title {
    color: var(--black);
    font-size: rem(24px);
    line-height: rem(30px);
    font-weight: 500;
  }

  .form {
    display: flex;
    flex-direction: column;
    margin-top: rem(40px);
		padding-left: rem(40px);
		
		fieldset {
			display: flex;
			flex-direction: column;
			gap: rem(24px);
			~ button {
				cursor: pointer;
				padding: initial;
				padding: 4px 40px;
				margin-right: auto;
				margin-left: auto;
				margin-top: rem(24px);
			}
		}
    
		&__item {
      display: flex;
      flex-direction: column;

			width: rem(700px);

      font-size: rem(15px);
      line-height: rem(18px);
      font-weight: 600;
			

      .label {
        color: #8690a5;
      }
      .input {
        border: initial;
        border-radius: 0.8px;
        background: #fff;
        height: rem(28px);
        flex-shrink: 0;
				width: calc(100% - 28px);
        color: #1e75e5;
				font-weight: inherit;
				font-size: inherit;
				&:read-only {
					~ button {
						display: block;
					}
				}
				~ button {
					display: none;
				}
      }
			button {
				cursor: pointer;
				padding: initial;
				border: initial;
				background-color: transparent;
				flex-shrink: 0;
				width: rem(28px);
				height: rem(28px);
				color: transparent;
				transition: 0.4s ease all;
			}
			.input-wrapper {
				&:hover {

					button {
						color: #1E75E5;
					}
				}
			}
			* + .input-wrapper {
				margin-top: rem(8px);
				border: 1px solid #DDD;
				border-radius: 0.8px;
        background: #fff;
			}
    }
  }
}
