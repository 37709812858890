@import '@/assets/styles/mixins';

.error-page {
	display: flex;
	flex: 1;
	background: url('/assets/images/preview.png') no-repeat center center;
	&__layer {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex: 1;
		color: var(--white);
		background: rgba(#000, 0.6);
		padding: rem(48px);
	}
	&__logo {
		display: inline-flex;
		height: rem(30px);
	}
	&__description {
		text-transform: uppercase;
		font-size: rem(24px);
		font-weight: 700;
		line-height: 1;
  	text-wrap: balance;
	}
	* + &__description {
		margin-top: rem(16px);
	}
}