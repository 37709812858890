@import '@/assets/styles/mixins';

.login-page {
  display: flex;
  flex: 1;
  background: var(--black);
  &__layer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    color: var(--white);
    background: url('/assets/images/preview.png') no-repeat center center / cover;
    padding: rem(48px) rem(16px);
  }
  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__sponsor {
    margin: auto auto 0;
    width: rem(184px);
    height: rem(53px);
    color: inherit;
  }
}

.login-page-title {
  display: flex;
  flex-direction: column;
  gap: rem(10px);
  color: var(--white);
  max-width: rem(520px);
  &__logo {
    display: flex;
    text-transform: uppercase;
    gap: rem(10px);
    font-size: 40px;
    line-height: 0.8;
    letter-spacing: 3.0px;
    font-weight: 600;
    svg {
      width: 49px;
      height: 30px;
    }
  }
  &__title {
    font-size: rem(26px);
    line-height: rem(28px);
    font-weight: 700;
    text-wrap: balance;
  }
  &__subtitle {
    color: var(--cyan);
    font-size: rem(16px);
    text-wrap: balance;
  }
}

.login-page-form {
  max-width: rem(500px);

  position: relative;
  display: flex;
  margin: rem(40px) 0;
  flex: 1;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  border-radius: rem(20px);
  padding: rem(40px) rem(22px);
  color: var(--black);
}

.login-form {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  &__title {
    display: inline-flex;
    justify-content: center;
    font-size: rem(30px);
    font-weight: 700;
  }
  &__desc {
    margin-top: rem(8px);
    display: inline-flex;
    justify-content: center;
    font-size: rem(16px);
    text-align: center;
    text-wrap: balance;
  }

  &__link {
    margin-left: auto;
    display: inline-flex;
    text-decoration: underline;
    font-size: rem(18px);
    font-weight: 700;
    color: var(--purple);
  }
  &__content {
    text-align: center;
    margin: auto 0;
    fieldset {
      margin-bottom: rem(20);
    }

    * + .input-wrapper {
      margin-top: rem(30px);
    }
  }

  &__controller {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: rem(10px);
  }

  > .button {
    margin: auto;
    margin-bottom: initial;
  }

  .edit-number-list {
    gap: rem(10px);
  }

  &-content {
    fieldset + button {
      display: block;
      margin-left: auto;
    }
  }
  .set-new-password-content {
    fieldset {
      display: flex;
      flex-direction: column;
      margin-top: rem(40px);
      margin-bottom: rem(20px);
      * + .input-wrapper {
        margin-top: rem(20px);
      }
      .error {
        line-height: 2;
        color: var(--status-error);
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      li {
        display: inline-flex;
        align-items: center;
      }
    }

  }
}

@media screen and (min-width: 992px) {
  .login-page {
    &__layer {
      padding: rem(48px);
    }
    &__content {
      flex-direction: row;
      align-items: center;
      gap: rem(40px);
    }
    &__sponsor {
      position: absolute;
      bottom: 40px;
      left: 25%;
    }
  }
  .login-page-title {
    gap: initial;
    &__logo {
      align-items: center;
      gap: rem(10px);
      font-size: 70px;
      font-style: normal;
      font-weight: 800;
      line-height: 79px;
      letter-spacing: 3.5px;
      svg {
        width: 83px;
        height: 50px;
      }
    }
    &__title {
      margin-top: rem(2);
      font-size: rem(40px);
      line-height: initial;
    }
    &__subtitle {
      margin-top: rem(46px);
      font-size: rem(26px);
    }
  }

  .login-page-form {
    width: rem(500px);
    .login-form {
      &__content {
        text-align: center;
        margin-top: rem(40px);
      }
      > .button {
        margin-top: rem(40px);
      }
    }
    
  }
}