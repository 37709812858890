@import '@/assets/styles/mixins';

.menu {
	display: flex;
  flex-direction: column;
  width: rem(150px);
  min-height: 100dvh;
  padding: rem(54px) rem(12px) rem(7px);
  background-color: var(--white);
  &__logo {
    display: flex;
    justify-content: center;
  }

  &-list {
    display: flex;
    flex-direction: column;
    margin-top: rem(60px);
    gap: rem(48px);
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: rem(12px);
      padding: rem(14px);
      color: #9475ff;
      background-color: transparent;
      border-radius: rem(14px);
      &.active {
        color: #6071ff;
        background-color: rgba(148, 117, 255, 0.08);
      }
    }
    &__name {
      text-align: center;
      font-size: rem(18px);
      font-weight: 500;
    }
  }

	&-footer {
		text-align: center;
		margin-top: auto;
		&__build {
			font-size: rem(8px);
			color: #8690A5;
		}
	}
}
