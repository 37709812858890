@import '@/assets/styles/mixins';

.token-create {
	&-content {
		display: flex;
		justify-content: center;
		margin-top: rem(50px);
		gap: rem(120px);
		&__asside {
			display: flex;
			flex-direction: column;

			button {
				position: relative;
				height: rem(38px);
				width: rem(140px);
				padding: rem(7px) rem(15px);
				border-radius: 20px;
				border: 2px solid;
				border-color: #092E49;
				color: #092E49;
				background-color: #fff;
				justify-content: center;
				align-items: center;
				font-size: rem(16px);
				font-weight: 400;
				&:hover,
				&:active {
					border-color: #092E49;
					color: #092E49;
					background-color: #fff;
					svg {
						left: rem(5px);
					}
				}
				&:focus {
					background-color: #fff;
				}
				&[disabled] {
					border-color: var(--grey);
				}

				svg {
					transition: 0.4s ease all;
					position: absolute;
					left: rem(10px);
					height: 14px;
					width: 14px !important;
				}
			}
		}
	}

	* + &-step-list {
		margin-top: rem(48px);
	}
	&-step-list + button {
		margin-top: auto;
	}

	&-step-list {
		display: flex;
		flex-direction: column;
		gap: rem(10px);
		width: rem(300px);
		color: #092E49;
		font-size: 18px;
		font-weight: 500;
		&__index {
			display: flex;
			align-items: center;
			justify-content: center;
			width: rem(59px);
			height: rem(55px);
			flex-shrink: 0;
			border-radius: rem(10px);
			border: 1px solid #C4C4C4;
		}
		&__item {
			display: flex;
			align-items: center;
			padding-left: rem(6px);
			padding-right: rem(8px);
			border-radius: rem(10px);
			height: rem(70px);
			width: 100%;
			flex-shrink: 0;

			svg	{
				margin-left: auto;
				width: rem(24px) !important;
				height: rem(24px);
				flex-shrink: 0;
			}

			&.isActive {
				background-color: var(--white);
			}
		}
		* + &__text {
			margin-left: rem(27px);
		}
	}

	&-data-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: rem(420px);
		height: rem(524px);
		gap: rem(50px);
		padding: rem(32px) rem(21px) rem(36px);
		background-color: var(--white);
		border: 1px solid rgba(0, 0, 0, 0.22);
	
		&-header {
			text-align: center;
			color: #202124;
			font-weight: 400;

			&__title {
				font-size: rem(24px);
				line-height: rem(32px);
			}
			&__desc {
				margin-top: rem(8px);
				font-size: rem(16px);
				line-height: rem(20px);
			}
		}

		&-main {}
		&-footer {
			display: flex;
			align-items: center;
			justify-content:center;
			gap: rem(12px);
			margin-top: auto;
			button {
				height: rem(38px);
				width: initial;
				min-width: rem(140px);
				padding: rem(7px) rem(15px);
				border-radius: 20px;
				border: 2px solid;
				border-color: #6071FF;
				background-color: #6071FF;
				justify-content: center;
				align-items: center;
				font-size: rem(16px);
				font-weight: 400;
				&:hover {
					color: #6071FF;
					background-color: var(--white);
				}
				&[disabled] {
					border-color: var(--grey);
				}
			}
		}
	}
	
	&__title {
		color: #092E49;
		line-height: rem(36px);
		font-size: rem(30px);
		font-weight: 500;
	}
}