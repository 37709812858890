@import '@/assets/styles/mixins';

.otp-list {
  display: inline-flex;
  &__item {
    display: flex;
    flex-direction: column;
    width: rem(50px);
    height: rem(75px);
    color: var(--black);
    border: rem(3px) solid var(--grey);
    border-radius: rem(10px);
    overflow: hidden;

    &:has(> input.is-fill ) {
      border-color:  var(--blue);
    }
    &:has(> input:focus) {
      border-color: var(--black);
    }
   
    input {
      display: inline-flex;
      justify-content: center;
      border: initial;
      color: inherit;
      outline: none;
      text-align: center;
      width: 100%;
      flex-grow: 1;
      padding: 0;
      font-weight: 700;
      font-size: rem(40px);
      line-height: rem(48px);
      transition: 0.3s ease all;
      
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }
  * + &__item:not(:nth-child(4)) {
    margin-left: rem(6px);
  }
  &__item:nth-child(4) {
    margin-left: rem(12px);
  }
}

@media screen and (min-width: 992px) {
  .otp-list {
    &__item {
      width: rem(60px);
      height: rem(90px);
    }
    * + &__item:not(:nth-child(4)) {
      margin-left: rem(16px);
    }
    &__item:nth-child(4) {
      margin-left: rem(32px);
    }
  }
}
